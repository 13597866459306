import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

import VueAxios from './plugins/axios'

import {func} from './plugins/func'

import Moment from 'vue-moment'
Vue.use(Moment);

import VueSignaturePad from 'vue-signature-pad';
Vue.use(VueSignaturePad);

Vue.prototype.$func = func

// Globale Variablen
// Vue.prototype.$api_endpoint = 'http://localhost/reifen/api.php'

/*
Vue.prototype.$header_title = 'Reifen Hüska'
Vue.prototype.$header_color = '#383083'
Vue.prototype.$header_logo = '@/assets/images/logo_hueska.png'

Vue.prototype.$api_endpoint = 'https://api.reifen-hueska.de/api/api.php'
Vue.prototype.$api_pdf_endpoint = 'https://api.reifen-hueska.de/create_pdf.php'
Vue.prototype.$api_upload = 'https://api.reifen-hueska.de/upload/'
Vue.prototype.$api_hostname = window.location.host

Vue.prototype.$api_endpoint = 'http://localhost/reifen/api.php'
*/



if (window.location.hostname.includes("reifen-hueska.de")){
  Vue.prototype.$header_title = 'Reifen Hüska'
  Vue.prototype.$header_color = '#383083'
  Vue.prototype.$header_logo = '@/assets/images/logo_hueska.png'

  Vue.prototype.$api_endpoint = 'https://api.reifen-hueska.de/api/api.php'
  Vue.prototype.$api_pdf_endpoint = 'https://api.reifen-hueska.de/create_pdf.php'
  Vue.prototype.$api_upload = 'https://api.reifen-hueska.de/upload/'
  Vue.prototype.$api_hostname = window.location.host
  Vue.prototype.$sender_email = 'abholung@reifen-hueska.de'
  Vue.prototype.$sender_name = 'Reifen Hüska'
  Vue.prototype.$sender_telefon = '+49 (0)2775 578 14 52'


}else if (window.location.hostname.includes("reifen-muka.de")) {
  Vue.prototype.$header_title = 'Reifen Muka'
  Vue.prototype.$header_color = '#0826e3'
  Vue.prototype.$header_logo = '@/assets/images/logo_muka.png'

  Vue.prototype.$api_endpoint = 'https://www.reifen-muka.de/api/api.php'
  Vue.prototype.$api_pdf_endpoint = 'https://www.reifen-muka.de/api/create_pdf.php'
  Vue.prototype.$api_upload = 'https://www.reifen-muka.de/api/upload/'
  Vue.prototype.$api_hostname = window.location.host
  Vue.prototype.$sender_email = 'abholung@reifen-muka.de'
  Vue.prototype.$sender_name = 'Reifen Muka'
  Vue.prototype.$sender_telefon = '+49 (0)9090 959 64 60'


}else if (window.location.hostname.includes("localhost")) {
  Vue.prototype.$header_title = 'Reifen Hüska - DEV'
  Vue.prototype.$header_color = '#383083'
  Vue.prototype.$header_logo = '@/assets/images/logo_hueska.png'

  Vue.prototype.$api_endpoint = 'https://www.migro.net/reifen/api.php'
  // Vue.prototype.$api_endpoint = 'https://api.reifen-hueska.de/api/api.php'
  Vue.prototype.$api_pdf_endpoint = 'https://www.migro.net/reifen/create_pdf.php'
  Vue.prototype.$api_upload = 'https://www.migro.net/reifen/upload/'
  Vue.prototype.$api_hostname = window.location.host
  Vue.prototype.$sender_email = 'abholung@reifen-hueska.de'
  Vue.prototype.$sender_name = 'Reifen Hüska'
  Vue.prototype.$sender_telefon = '+49 (0)2775 578 14 52'


}else if (window.location.hostname.includes("vercel")) {
  Vue.prototype.$header_title = 'Reifen Hüska - DEV'
  Vue.prototype.$header_color = '#383083'
  Vue.prototype.$header_logo = '@/assets/images/logo_hueska.png'


  Vue.prototype.$api_endpoint = 'https://www.migro.net/reifen/api.php'
  Vue.prototype.$api_pdf_endpoint = 'https://www.migro.net/reifen/create_pdf.php'
  Vue.prototype.$api_upload = 'https://www.migro.net/reifen/upload/'
  Vue.prototype.$api_hostname = window.location.host
  Vue.prototype.$sender_email = 'abholung@reifen-hueska.de'
  Vue.prototype.$sender_name = 'Reifen Hüska'
  Vue.prototype.$sender_telefon = '+49 (0)2775 578 14 52'

}



// Axios
Vue.use(VueAxios);



Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
