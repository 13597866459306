<template>
  <v-container>


  <v-card
      outlined
      elevation="7"
      :color="color"
      style="
        margin: 5px;
        width: 90%;
        max-width: 370px;
      "
  >

    <v-card-title>Tour Nr. {{tour_id}}</v-card-title>
    <v-card-text>
      <v-row style="
      text-align: left;
      padding-left: 14px;"><h3>{{note}}</h3></v-row>
      <v-row style="text-align: left; padding-left: 14px; padding-bottom: 15px;"><h3></h3></v-row>
      <v-row style="text-align: left; padding-left: 14px; padding-bottom: 15px;"><h3>Geplant für den: {{date}}</h3></v-row>
      <v-row style="text-align: left; padding-left: 14px; padding-bottom: 15px;"><h3>Fahrzeug: {{car_description}}</h3></v-row>

      <v-row style="padding-left: 15px;">Anzahl:&nbsp;<b>{{ qty }}</b></v-row>
      <v-row style="padding-left: 15px;">Artikel:&nbsp;<b>{{ article }}</b></v-row>
      <v-row style="padding-left: 15px;">Stops:&nbsp;<b>{{ stops }}</b></v-row>
      <v-row style="padding-left: 15px;">Sammelstelle:</v-row>
      <v-row style="padding-left: 15px;"><b style="color: white">{{ sammelstelle }}</b></v-row>
      <v-row style="padding-left: 15px;">Abfahrt von:</v-row>
      <v-row style="padding-left: 15px;"><b>{{ start_point }}</b></v-row>
      <v-row style="padding-left: 15px;">Rückfahrt zu:</v-row>
      <v-row style="padding-left: 15px;"><b>{{ end_point }}</b></v-row>

    </v-card-text>


    <v-card-actions>
      <v-spacer></v-spacer>


      <v-btn
          style="margin: 10px;"
      @click="selectTour"
      >
        Auswählen
      </v-btn>

    </v-card-actions>

  </v-card>

    <v-row justify="center">


      <v-dialog
          v-model="dialog"
          persistent
          max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">
            Tour Nr. {{tour_id}}
          </v-card-title>
          <v-card-text>Jetzt fahren?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="red"

                @click="dialog = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
                color="green"

                @click="startTour"
            >
              Ja
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

  </v-container>

</template>

<script>
export default {
  name: "CardTour",


  data () {
    return {

      dialog: '',
      color: 'white',

      note: '',
      date: '',
      qty: '',
      article: '',
      stops: '',
      sammelstelle: '',
      start_point: '',
      end_point: '',

      car_description: '',

      temp_tour_id: '',

    }
  },

  created() {
      // this.date = this.$moment(this.initial_date, "DD.MM.YYYY").format("YYYY-MM-DD")

    this.loadData()

  },


  methods:{

    async loadData(){
      console.log("this.tour_id: " + this.tour_id)

      if(this.tour_id == null){
        return
      }

      // Einstellungen
      var param = {
        table: "tour",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.tour_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log("result" + this.tour_id)
      console.log(result)

      this.temp_tour_id = result[0]["temp_tour_id"]
      this.note = result[0]["note"]
      this.date = result[0]["planed_date"]
      this.sammelstelle = result[0]["parking_address"]
      this.start_point = result[0]["start_point"]
      this.end_point = result[0]["end_point"]

      this.car_description = result[0]["car_description"]

      this.article = result[0]["article"].toString().substring(0, result[0]["article"].toString().length - 1)
      this.qty = result[0]["qty"]


      if(this.date == this.$moment(Date.now()).format("DD.MM.YYYY")){
        this.color = "red"
      }else{
        this.color = "white"
      }


      // Einstellungen
      param = {
        table: "task",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "tour_id",
        value: this.tour_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log(result)

      this.stops = result.length

    },

    selectTour(){
      console.log("selectTour")
      this.dialog = true
    },

    async startTour(){
      console.log("startTour")

      // Einstellungen
      var param = {
        table: "driver",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: localStorage.driver_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
        tour_id: this.tour_id,
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      this.dialog = false
      localStorage.tour_id = this.tour_id
      localStorage.tour_setdate = this.$moment(Date.now()).format("DD.MM.YYYY")

      this.$router.go()
    }



  },


  props:{

    tour_id:{
      type: String,
      default: null,
    },





  },





}
</script>

<style scoped>

</style>
