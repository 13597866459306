<template>

  <v-layout
      align-center
      justify-center
  >

    <v-container
    style="
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    "

    >
      <v-row
          v-if="sammelstelle != ''"
      style="
      position: absolute;
      top: 15px;
      left: 0px;
      width: 100%;
      height: 50px;
      background-color: red;
      "
      ><div style="margin-left: 25px; margin-top: 10px; color: #FFFFFF;"><h3>{{sammelstelle}}</h3></div></v-row>

      <div
          v-if="sammelstelle != ''"
          style="margin-top: 60px;"
      ></div>

      <v-row>


        {{message}}

        <CardTour
        v-for="tour in tours"
        :key="tour"
        v-bind:tour_id="tour"
        ></CardTour>


        <CardTask
            v-for="task in tasks"
            :key="task"
            v-bind:task_id="task"
        ></CardTask>

        <div
        v-if="notasks"
        style="
          position: absolute;
          top: 50px;
          left: 0px;
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;

"
        >
         <div
         style="width: 100%"
         >Keine Aufträge</div>
          <v-btn
          outlined
          @click="setTourPickupDate"
          >Tour erledigt</v-btn>

        </div>



      </v-row>

<!--
      <v-row>
        <v-btn
        @click="sendMail()"
        >TEST</v-btn>
      </v-row>
-->



    </v-container>





  </v-layout>


</template>

<script>

import CardTour from '@/components/CardTour'
import CardTask from '@/components/CardTask'
import Vue from "vue";



export default {
  name: "Home",

  components: {
    CardTour,
    CardTask,
  },

  data () {
    return {

      snackbar: '',
      snackbar_title: '',
      snackbar_color: 'red',

      notasks: false,

      message: '',

      timeout: 1000,

      driver_id: localStorage.driver_id,

      password: '',

      tour_id: localStorage.tour_id,
      tours: [],

      tasks: [],



      /* FÜR FUNKTIONSTEST */


      pickup_qty: 0,
      pickup_date: '',
      pickup_note: '',

      sammelstelle: '',

      reported_qty: '',

      driver_sign_text: '',
      img_sign_name: '',

      weight_total: 0,
      price_total: 0,


      items: [],

      customer_id: '',
      company: '',
      street: '',
      zipcode: '',
      city: '',
      phone: '',
      note: '',
      document_no: '',

      temp_task_id: '',

      group_type_id: '',
      group_type_text: '',

      rest: '0',



    }
  },

  created(){
    console.log("localStorage.tour_id: " + localStorage.tour_id)
    console.log(this.$moment(Date.now()).format("DD.MM.YYYY"))

    if (localStorage.driver_id == ""){
      this.$router.push({ name: 'Login' })
    }


    if(localStorage.tour_id == ""  || localStorage.tour_id == undefined){
      this.loadTour()
    }else{
      this.loadTasks(localStorage.tour_id)
    }


  },


  methods:{


    async sendMail(){

      this.task_id = "2026"

      // task
      var param = {
        table: "task",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: "2026", // 2026
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      this.company = result[0]["company"]
      this.group_type_id = result[0]["group_type_id"]
      // this.group_type_text = result[0]["group_type_text"]
      this.pickup_note = result[0]["pickup_note"]
      this.pickup_date = result[0]["pickup_date"]
      this.street = result[0]["street"]
      this.city = result[0]["city"]
      this.zipcode = result[0]["zipcode"]
      this.rest = result[0]["rest"]
      this.driver_id =  result[0]["driver_id"]
      this.temp_task_id =  result[0]["temp_task_id"]


      var email = ""

      if(result[0]["email"] != ""){
        email = result[0]["email"]
      }



      // Einstellungen
      param = {
        table: "driver",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.driver_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)

      if(result.length != 0){
        this.driver_sign_text = result[0]["sign_text"]
      }else{
        this.driver_sign_text = ""
      }




      // Einstellungen
      param = {
        table: "group_type",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.group_type_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log(result)

      this.group_type_text = result[0]["text"]




      // Einstellungen
      param = {
        table: "task_items",
        statement: "select",
        type: "list",
        where: "true",
        selector: "temp_task_id",
        value: this.temp_task_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log(result)


      this.items = []

      for (var i0 = 0; i0 < result.length; i0++) {

        this.reported_qty = Number(this.reported_qty) + Number(result[i0]["order_qty"])

        this.items.push({id:  result[i0]["id"], rating: result[i0]["rating"], order_qty: result[i0]["order_qty"], pickup_qty: result[i0]["pickup_qty"], article_name: result[i0]["article_name"], price: result[i0]["price"]})

      }




      // image (img_sign_name)
      param = {
        table: "images",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT * FROM images WHERE task_id='" + this.task_id + "' AND type='signatur'",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log("images")
      console.log(result)

      if(result.length != 0){
        this.img_sign_name = result[0]["name"]
      }else{
        this.img_sign_name = ""
      }


      // group_type_id
      param = {
        table: "group_type_email",
        statement: "select",
        type: "list",
        where: "true",
        selector: "group_type_id",
        value: this.group_type_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log(result)



      for (var i1 = 0; i1 < result.length; i1++) {
        if(email == ""){
          email = result[i1]["email"]
        }else{
          email = email + ", " + result[i1]["email"]
        }
      }


      await this.createUebergabeschein()




      // send mail
      param = {
        sendmail: "true",
        mailto: "mail@michael-gross.de",
        mailfrom: "mail@michael-gross.de",
        subject: "Übergabeschein - " + this.task_id,
        message: "" +
            "Wird gesendet an: " + email +
            "\\r\\n" +
            "\\r\\n" +
            "\\r\\n" +
            "Sehr geehrte Damen und Herren,  \\r\\n" +
            "\\r\\n" +
            "Vielen Dank für Ihren Abholauftrag dieser wurde soeben abgeholt. Anbei erhalten Sie den Abholschein als Beleg für Ihre Unterlagen." +
            "\\r\\n" +
            "Bei Reklamationen oder Fragen erreichen Sie uns telefonisch unter: +49 (0)2775 578 14 52 \\r\\n" +
            "\\r\\n" +
            "Mit freundlichen Grüssen \\r\\n" +
            "\\r\\n" +
            "\\r\\n" +
            "Hüska Reifen\\r\\n" +
            "\\r\\n" +
            "\\r\\n",


        file_name: "auftrag_" + this.task_id + ".pdf",
        file_path: "upload/" + "auftrag_" + this.task_id + ".pdf",

      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log(result)


    },

    async createUebergabeschein(){

      var param = {
        vorlage_name: "uebergabeschein.png",
        pdf_autor: "Hüska GmbH",
        pdf_name: "auftrag_" + this.task_id + ".pdf",
        pdf_title: "Übergabeschein",
        pdf_subject: "",
        pdf_name_crypt: "auftrag_" + this.task_id + ".pdf",
      }

      var data = {


        text: {
          0: {
            text: this.company.substring(0,50),
            x: "10",
            y: "40",
          },
          1: {
            text: this.street,
            x: "10",
            y: "50",
          },
          2: {
            text: this.zipcode,
            x: "10",
            y: "55",
          },
          3: {
            text: this.city,
            x: "25",
            y: "55",
          },
          4: {
            text: this.pickup_date,
            x: "80",
            y: "80",
          },

          5: {
            text: "Übergabeschein -Nr.: " + this.task_id, // Gemeldete Reifen // this.reported_qty
            x: "10",
            y: "23",
          },

          6: {
            text: this.group_type_text,   // Abgeholte Reifen // this.pickup_qty
            x: "135",
            y: "77",
          },



          /*
          5: {
            text: "", // Gemeldete Reifen // this.reported_qty
            x: "80",
            y: "163",
          },

          6: {
            text: "",   // Abgeholte Reifen // this.pickup_qty
            x: "80",
            y: "175",
          },
           */
          7: {
            text: this.rest,         // Restreifen
            x: "80",
            y: "187",
          },
          8: {
            text: this.pickup_note,
            x: "10",
            y: "212",
          },
          9: {
            text: "", // Unterschrift Kunde
            x: "10",
            y: "245",
          },
          10: {
            text: "gez. " + this.driver_sign_text, // Unterschrift Fahrer
            x: "150",
            y: "245",
          },
          11: {
            text: "",
            x: "0",
            y: "5",
          },
          12: {
            text: "",
            x: "0",
            y: "5",
          },
          13: {
            text: "",
            x: "0",
            y: "5",
          },
          14: {
            text: "",
            x: "0",
            y: "5",
          },
          15: {
            text: "",
            x: "0",
            y: "5",
          }



        },


        image: {
          0: {
            name: this.img_sign_name,
            x: "10",
            y: "225",
            w: "40",
            h: "25",
          },

        },




      }



      /* Reifen Positionen werden hinzugefügt */

      var y = 110


      console.log("TEST")

      for (var i1 = 0; i1 <  this.items.length; i1++) {

        data["text"][11 + i1].text = this.items[i1]["order_qty"] + "                                        " + this.items[i1]["pickup_qty"]  + "                              " + this.items[i1]["article_name"]  + "                     " + "160103"
        data["text"][11 + i1].x = "10"
        data["text"][11 + i1].y = y

        y = Number(y) + 10

      }

      var request = "param=" + encodeURIComponent(JSON.stringify(param)) + "&data=" + encodeURIComponent(JSON.stringify(data));
      console.log(Vue.prototype.$api_pdf_endpoint + "?" + request)

      const config = {
        method: 'GET',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        responseType: 'blob',
      }

      try {

        await Vue.prototype.$axios
            .get(Vue.prototype.$api_pdf_endpoint + "?" + request, config)
            .then(response => (this.responseText = response))

        console.log(this.responseText)
        /*
                let blob = new Blob([this.responseText.data], { type: 'application/pdf' }),
                    url = window.URL.createObjectURL(blob)

                window.open(url) // Mostly the same, I was just experimenting with differen
        */

        // var random_string = Math.random().toString(32).substring(2, 5) + Math.random().toString(32).substring(2, 5);
        // return this.responseText["data"]

      }catch{

        return {"status": "404"}

      }

    },





    btnLogout(){
      console.log("btnLogout")

      this.$router.push({ name: 'Login' })
    },


    async loadTour(){
      console.log("loadTour")

      // Einstellungen
      var param = {
        table: "tour",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "WHERE driver_id='" + this.driver_id + "' AND (pickup_date IS NULL OR pickup_date='') ORDER BY planed_date_timestamp ASC",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log("result -- TOUR")
      console.log(result)


      if(result.length != 0){

        if (result["status"] == "404"){
          this.$router.push({ name: 'Error' })
        }

      }


      if(result.length == 0){
        this.message = "Keine Touren vorhanden!"
        return
      }

      this.tours = []

      for (var i1 = 0; i1 < result.length; i1++) {

        // ToDo: Prüfen ob Tour nicht von Gestern
        /*
        console.log(result[i1]["planed_date_timestamp"])

        var date1 = new Date(this.$moment(result[i1]["planed_date_timestamp"], "YYYY-MM-DD").format("MM/DD/YYYY"))
        var date2 = new Date(this.$moment(Date.now()).format("MM/DD/YYYY"))

        console.log(date1)
        console.log(date2)



// To calculate the time difference of two dates
        var Difference_In_Time = date2.getTime() - date1.getTime()

        console.log(Difference_In_Time)


// To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)

        console.log(Difference_In_Days)
        console.log(this.$moment(Date.now()).diff(this.$moment(result[i1]["planed_date_timestamp"], "YYYY-MM-DD"), 'days'))
*/


        if(this.$moment(Date.now()).diff(this.$moment(result[i1]["planed_date_timestamp"], "YYYY-MM-DD"), 'days') > 0){
          console.log("Tour in der Vergangenheit")
        }else{

          if(result[i1]["id"] != null && result[i1]["id"] != ""){
            this.tours.push(result[i1]["id"])
          }

        }

      }


        if(this.tours.length == 0){
            this.message = "Keine Touren vorhanden!"
            return
        }


      console.log("this.tours")
      console.log(this.tours)

    },

    async loadTasks(){

      console.log("loadTasks")


      // Einstellungen
      var param1 = {
        table: "tour",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "WHERE id='" + this.tour_id + "'",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {
      }

      var result1 = await this.$func.queryAPI(param1, data1)
      console.log("result1")
      console.log(result1)

      this.sammelstelle = result1[0]["parking_address"]



      // Einstellungen
      var param = {
        table: "task",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "WHERE tour_id='" + this.tour_id + "' AND (pickup_date IS NULL OR pickup_date='') ORDER BY tour_position ASC",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      if(result.length != 0){

        if (result["status"] == "404"){
          this.$router.push({ name: 'Error' })
        }

      }




      if(result.length == 0){
        this.setTourPickupDate()

        return
      }

      this.tasks = []

      for (var i1 = 0; i1 < result.length; i1++) {
        this.tasks[i1] = result[i1]["id"]
      }

      console.log(this.tasks)



    },

    async setTourPickupDate(){
      // Einstellungen
      var param = {
        table: "tour",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: this.tour_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
        pickup_date: this.$moment(Date.now()).format("DD.MM.YYYY"),
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      this.$router.push({ name: 'Login' })


    },



  }


}
</script>

<style scoped>

</style>
