<template>
<div
style="
position: absolute;
top: 50px;
left: 0px;
width: 100%;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
   align-items: center;

"
>
  <h2>Ladeliste</h2>

  <div
  style="width: 100%"
  ></div>

  <CardTaskPickup
      v-for="task in tasks"
      :key="task"
      v-bind:task_id="task.id"
  ></CardTaskPickup>


 <div
     style="width: 100%;"
 v-if="tasks.length === 0"
 >
   KEINE REIFEN GELADEN
 </div>


</div>
</template>

<script>
import CardTaskPickup from "@/components/CardTaskPickup.vue";

export default {
  name: "LoadingList",
  components: {CardTaskPickup},

  data () {
    return {

      tour_id: '',
      driver_id: '',
      pickup_date: '',
      tasks: [],


    }
  },


  created() {



  },

  mounted() {

    this.tour_id = localStorage.tour_id;
    this.driver_id = localStorage.driver_id;
    this.pickup_date = this.$moment(Date.now()).format("DD.MM.YYYY")
    this.loadData();

  },



  methods:{

   async loadData(){
      console.log("loadData")


      // Einstellungen
      var param = {
        table: "task",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT id FROM task WHERE driver_id='" + this.driver_id + "' AND pickup_date='" + this.pickup_date + "' AND pickup_date IS NOT NULL ORDER BY pickup_time DESC",
      }




      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)
      console.log("länge: " + result.length)

     if(result.length === 0){
       return
     }

     this.tasks = result;









    },

  }


}
</script>

<style scoped>

</style>
