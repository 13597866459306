import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Detail from '../views/Detail'
import Confirmation from '../views/Confirmation'
import Signature from '../views/Signature'
import Done from '../views/Done'
import ModeSelect from '../views/ModeSelect'
import Stock from '../views/Stock'
import Error from '../views/Error'
import LoadingList from '../views/LoadingList'


Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'ModeSelect',
    component: ModeSelect
  },
  {
    path: '/',
    name: 'ModeSelect',
    component: ModeSelect
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/Detail/:id',
    name: 'Detail',
    component: Detail
  },
  {
    path: '/Confirmation/:id',
    name: 'Confirmation',
    component: Confirmation
  },
  {
    path: '/Signature/:id',
    name: 'Signature',
    component: Signature
  },
  {
    path: '/Done',
    name: 'Done',
    component: Done
  },
  {
    path: '/ModeSelect',
    name: 'ModeSelect',
    component: ModeSelect
  },
  {
    path: '/LoadingList',
    name: 'LoadingList',
    component: LoadingList
  },
  {
    path: '/Stock',
    name: 'Stock',
    component: Stock
  },
  {
    path: '/Error',
    name: 'Error',
    component: Error
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
