<template>


  <v-card
      outlined
      elevation="7"
      style="
        margin: 5px;
        width: 90%;
        max-width: 350px;
"
  >

    <v-card-title><h5>{{company}}</h5></v-card-title>
    <v-card-text>
      <v-row style="text-align: left; padding-left: 14px;"><h3>{{street}}</h3></v-row>
      <v-row style="text-align: left; padding-left: 14px; padding-bottom: 15px;"><h3>{{zipcode}} {{city}}</h3></v-row>
      <v-row style="padding-left: 15px;">Artikel:&nbsp;<b>{{article_name}}</b></v-row>
      <v-row style="padding-left: 15px;">Anzahl:&nbsp;<b>{{pickup_qty}}</b></v-row>
      <div
      style="margin: 15px;"
      ></div>
      <v-row style="padding-left: 15px;">Geladen am:&nbsp;<b>{{pickup_date}} {{pickup_time}}</b></v-row>

      <v-img
          style="margin: 10px;"
          :src="'https://api.reifen-hueska.de/upload/' + image_url"
          width="100px"
          height="150px"
      ></v-img>

    </v-card-text>


    <v-card-actions>
      <v-spacer></v-spacer>


      <v-btn
          @click="makeCall"
          icon>
        <v-icon>mdi-phone</v-icon>
      </v-btn>



    </v-card-actions>

  </v-card>
</template>

<script>
export default {
  name: "CardTask",

  data () {
    return {

      modal1: '',
      date: '',

      company: '',
      street: '',
      zipcode: '',
      city: '',
      phone: '',
      note: '',

      article_name: '',
      order_qty: '',
      pickup_qty: '',
      pickup_date: '',
      pickup_time: '',

      image_url: '',


    }
  },

  created() {

    if (this.task_id == null){
      return
    }

      this.loadData()

  },

  methods:{

    openTask(){
      // this.$router.push({ name: 'Detail', param: {id: this.task_id} })
      // this.$router.push({ path: 'Detail' + "/" + id, params:{id: this.id} })
      this.$router.push({ path: 'Detail' + "/" + this.task_id, params:{id: this.task_id} })
    },

    makeCall(){
      console.log("makeCall")
      console.log(this.phone)

      if(this.phone == ""){
        return
      }

      window.open('tel:' + this.phone, "_self");

    },

    startNavigation(){

      // http://maps.google.com/maps?saddr=street+adress&daddr=street+address
      // google.navigation:q=
      // https://www.google.com/maps/dir/My+Location/Spitzeng%C3%A4ssle+8,+72800+Eningen+unter+Achalm
      // https://maps.google.com/?saddr=My%20Location&daddr=myDestinationAddress


      // window.open('https://maps.google.com/?saddr=My%20Location&daddr=' + decodeURI(this.street +  "+" + this.zipcode +  "+" + this.city), "_self");
      window.open('https://www.google.com/maps/dir/My+Location/' + decodeURI(this.street +  "+" + this.zipcode +  "+" + this.city), "_self");

    },


    async loadData(){
      console.log("this.task_id: " + this.task_id)

      // Einstellungen
      var param = {
        table: "task",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.task_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      this.company = result[0]["company"]
      this.street = result[0]["street"]
      this.zipcode = result[0]["zipcode"]
      this.city = result[0]["city"]
      this.phone = result[0]["phone"]

      this.note = result[0]["note"]
      this.date = result[0]["planed_date"]

      this.order_qty = result[0]["order_qty"]
      this.pickup_qty = result[0]["pickup_qty"]
      this.pickup_date = result[0]["pickup_date"]
      this.pickup_time = result[0]["pickup_time"]
      this.article_name = result[0]["article_name"]





      // Einstellungen
      var param2 = {
        table: "iamges",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT name FROM images WHERE task_id='" + this.task_id + "' AND type='signatur'",

      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data2 = {
      }

      var result2 = await this.$func.queryAPI(param2, data2)
      console.log("image")
      console.log(result2)


      if(result2.length != 0){
        this.image_url = result2[0]["name"]
      }


    },

    selectTour(){
      console.log("selectTour")
      this.dialog = true
    },

    startTask(){
      this.dialog = false


      this.$router.go()
    }



  },


  props:{

    task_id:{
      type: String,
      default: null,
    },





  },

}
</script>

<style scoped>

</style>
