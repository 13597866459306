<template>
  <v-layout
      align-center
      justify-center
  >



    <v-container
        fluid
    >


        <VueSignaturePad width="100%" :height="height" ref="signaturePad" style="background-color: #EEEEEE " />
        <v-btn
            @click="undo"
            outlined
        >Löschen</v-btn>&nbsp;
      <v-btn
            :disabled="btnsave"
            style="

            background-color: #00C133;"
            @click="save"
            outlined
      >Weiter</v-btn>


    </v-container>

  </v-layout>
</template>

<script>
import Vue from "vue";

export default {
  name: "Signature",


  data () {

    return {

      height: "0px",
      task_id: '',

      pickup_qty: 0,
      pickup_date: '',
      pickup_note: '',

      btnsave: false,


      reported_qty: '',

      driver_id: '',

      driver_sign_text: '',
      img_sign_name: '',

      weight_total: 0,
      price_total: 0,

      tour_id: '',

      items: [],

      customer_id: '',
      company: '',
      street: '',
      zipcode: '',
      city: '',
      phone: '',
      note: '',
      document_no: '',

      temp_task_id: '',

      group_type_id: '',
      group_type_text: '',

      rest: '0',

    }
  },

  created(){

    this.height = Number(screen.height - 150) + "px"

    if(this.$route.params.id != undefined) {
      this.task_id = this.$route.params.id
    }else{
      this.$router.push({ name: 'Home' })
    }

  },


  methods: {

    undo() {
      // this.$refs.signaturePad.undoSignature();
      this.$refs.signaturePad.clearSignature();

    },

    async save() {


      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      if(isEmpty){
        alert("Unterschrift fehlt")
        return
      }

      this.btnsave = true

      // console.log(isEmpty);
      // console.log(data);

      var data_new = data.split(",")

      const byteCharacters = atob(data_new[1]);

      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], {type: "image/png"});


      var formData = new FormData();
      formData.append("files[]", blob, "signature.png" );
      formData.append("description", "signatur")
      formData.append("type", "signatur")
      formData.append("selector", "task_id")
      formData.append("value", this.task_id)
      formData.append("table", "images")

      var xhttp = new XMLHttpRequest();

      // Set POST method and ajax file path
      xhttp.open("POST", Vue.prototype.$api_endpoint, true);

      // call on request changes state
      xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {

          var response = this.responseText;

          console.log("response")
          console.log(response);
        }

      };


      // console.log(formData)


      // Send request with data
      await xhttp.send(formData);

      // ToDo: Send Mail
      await this.sendMail()

      // Finish
      this.$router.push({name: 'Done', params: {id: this.task_id}})

    },

    async sendMail(){
      console.log("sendMail")

      // task
      var param = {
        table: "task",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.task_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      this.company = result[0]["company"]
      this.group_type_id = result[0]["group_type_id"]
      // this.group_type_text = result[0]["group_type_text"]
      this.pickup_note = result[0]["pickup_note"]
      this.pickup_date = result[0]["pickup_date"]
      this.street = result[0]["street"]
      this.city = result[0]["city"]
      this.zipcode = result[0]["zipcode"]
      this.rest = result[0]["rest_before"]
      this.driver_id =  result[0]["driver_id"]
      this.temp_task_id =  result[0]["temp_task_id"]


      var email = ""

      if(result[0]["email"] != ""){
        email = result[0]["email"]
      }


      if(email == ""){
        email = Vue.prototype.$sender_email
      }else{
        email = email + "; " + Vue.prototype.$sender_email
      }

      console.log(email)


      // Einstellungen
      param = {
        table: "driver",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.driver_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)

      this.driver_sign_text = result[0]["sign_text"]



      // Einstellungen
      param = {
        table: "group_type",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.group_type_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log(result)

      this.group_type_text = result[0]["text"]


      // Einstellungen
      param = {
        table: "task_items",
        statement: "select",
        type: "list",
        where: "true",
        selector: "temp_task_id",
        value: this.temp_task_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log(result)


      this.items = []

      for (var i0 = 0; i0 < result.length; i0++) {

        this.reported_qty = Number(this.reported_qty) + Number(result[i0]["order_qty"])

        this.items.push({id:  result[i0]["id"], rating: result[i0]["rating"], order_qty: result[i0]["order_qty"], pickup_qty: result[i0]["pickup_qty"], article_name: result[i0]["article_name"], price: result[i0]["price"]})

      }




      // image (img_sign_name)
      param = {
        table: "images",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT * FROM images WHERE task_id='" + this.task_id + "' AND type='signatur'",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log("images")
      console.log(result)

      if(result.length != 0){
        this.img_sign_name = result[0]["name"]
      }else{
        this.img_sign_name = ""
      }


      // group_type_id
      param = {
        table: "group_type_email",
        statement: "select",
        type: "list",
        where: "true",
        selector: "group_type_id",
        value: this.group_type_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log(result)



      for (var i1 = 0; i1 < result.length; i1++) {
        if(email == ""){
          email = result[i1]["email"]
        }else{
          email = email + "; " + result[i1]["email"]
        }
      }


      await this.createUebergabeschein()


      // send mail
      param = {
        sendmail: "true",
        mailto: email,
        mailfrom: Vue.prototype.$sender_email,
        subject: "Abholschein - " + this.task_id,
        message: "" +
            /*
            "Wird gesendet an: " + email +
            "\\r\\n" +
            "\\r\\n" +
            "\\r\\n" +
             */
            "Sehr geehrte Damen und Herren,  \\r\\n" +
            "\\r\\n" +
            "Vielen Dank für Ihren Abholauftrag dieser wurde soeben abgeholt. Anbei erhalten Sie den Abholschein als Beleg für Ihre Unterlagen." +
            "\\r\\n" +
            "Bei Reklamationen oder Fragen erreichen Sie uns telefonisch unter: " + Vue.prototype.$sender_telefon + " \\r\\n" +
            "\\r\\n" +
            "Mit freundlichen Grüssen \\r\\n" +
            "\\r\\n" +
            "\\r\\n" +
            "Ihr Team von " + Vue.prototype.$sender_name + " \\r\\n" +
            "\\r\\n" +
            "\\r\\n",

        file_name: "auftrag_" + this.task_id + ".pdf",
        file_path: "upload/" + "auftrag_" + this.task_id + ".pdf",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      // E-Mail wir nicht gesendet wenn auf localhost läuft
      if(!window.location.hostname.includes("localhost") && !window.location.hostname.includes("vercel")){
        result = await this.$func.queryAPI(param, data)
        console.log(result)
      }else{
        alert("TEST MODUS (localhost) E-Mail wurde nicht gesendet!")
      }


    },

    async createUebergabeschein(){

      var param = {
        vorlage_name: "uebergabeschein.png",
        pdf_autor: "Hüska GmbH",
        pdf_name: "auftrag_" + this.task_id + ".pdf",
        pdf_title: "Übergabeschein",
        pdf_subject: "",
        pdf_name_crypt: "auftrag_" + this.task_id + ".pdf",
      }

      var data = {


        text: {
          0: {
            text: this.company.substring(0,50),
            x: "10",
            y: "40",
          },
          1: {
            text: this.street,
            x: "10",
            y: "50",
          },
          2: {
            text: this.zipcode,
            x: "10",
            y: "55",
          },
          3: {
            text: this.city,
            x: "25",
            y: "55",
          },
          4: {
            text: this.pickup_date,
            x: "80",
            y: "80",
          },

          5: {
            text: "Übergabeschein -Nr.: " + this.task_id, // Gemeldete Reifen // this.reported_qty
            x: "10",
            y: "23",
          },

          6: {
            text: this.group_type_text,   // Abgeholte Reifen // this.pickup_qty
            x: "135",
            y: "77",
          },



          /*
          5: {
            text: "", // Gemeldete Reifen // this.reported_qty
            x: "80",
            y: "163",
          },

          6: {
            text: "",   // Abgeholte Reifen // this.pickup_qty
            x: "80",
            y: "175",
          },
           */
          7: {
            text: this.rest,         // Restreifen
            x: "80",
            y: "187",
          },
          8: {
            text: this.pickup_note,
            x: "10",
            y: "212",
          },
          9: {
            text: "", // Unterschrift Kunde
            x: "10",
            y: "245",
          },
          10: {
            text: "gez. " + this.driver_sign_text, // Unterschrift Fahrer
            x: "150",
            y: "245",
          },
          11: {
            text: "",
            x: "0",
            y: "5",
          },
          12: {
            text: "",
            x: "0",
            y: "5",
          },
          13: {
            text: "",
            x: "0",
            y: "5",
          },
          14: {
            text: "",
            x: "0",
            y: "5",
          },
          15: {
            text: "",
            x: "0",
            y: "5",
          }



        },


        image: {
          0: {
            name: this.img_sign_name,
            x: "10",
            y: "225",
            w: "40",
            h: "25",
          },

        },




      }



      /* Reifen Positionen werden hinzugefügt */

      var y = 110

      for (var i1 = 0; i1 <  this.items.length; i1++) {

        data["text"][11 + i1].text = this.items[i1]["order_qty"] + "                                        " + this.items[i1]["pickup_qty"]  + "                              " + this.items[i1]["article_name"]  + "                     " + "160103"
        data["text"][11 + i1].x = "10"
        data["text"][11 + i1].y = y

        y = Number(y) + 10

      }

      var request = "param=" + encodeURIComponent(JSON.stringify(param)) + "&data=" + encodeURIComponent(JSON.stringify(data));
      console.log(Vue.prototype.$api_pdf_endpoint + "?" + request)

      const config = {
        method: 'GET',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        responseType: 'blob',
      }

      try {

        await Vue.prototype.$axios
            .get(Vue.prototype.$api_pdf_endpoint + "?" + request, config)
            .then(response => (this.responseText = response))

        console.log(this.responseText)
/*
        let blob = new Blob([this.responseText.data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob)

        window.open(url) // Mostly the same, I was just experimenting with differen
*/

        // var random_string = Math.random().toString(32).substring(2, 5) + Math.random().toString(32).substring(2, 5);
        // return this.responseText["data"]

      }catch{

        return {"status": "404"}

      }

    },





  }

}
</script>

<style scoped>

</style>
